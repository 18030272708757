"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function OpenEdXInstanceDeploymentCreateFromJSON(json) {
    return OpenEdXInstanceDeploymentCreateFromJSONTyped(json, false);
}
exports.OpenEdXInstanceDeploymentCreateFromJSON = OpenEdXInstanceDeploymentCreateFromJSON;
function OpenEdXInstanceDeploymentCreateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
    };
}
exports.OpenEdXInstanceDeploymentCreateFromJSONTyped = OpenEdXInstanceDeploymentCreateFromJSONTyped;
function OpenEdXInstanceDeploymentCreateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
    };
}
exports.OpenEdXInstanceDeploymentCreateToJSON = OpenEdXInstanceDeploymentCreateToJSON;
