"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function WatchedPullRequestFromJSON(json) {
    return WatchedPullRequestFromJSONTyped(json, false);
}
exports.WatchedPullRequestFromJSON = WatchedPullRequestFromJSON;
function WatchedPullRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'forkName': !runtime_1.exists(json, 'fork_name') ? undefined : json['fork_name'],
        'targetForkName': !runtime_1.exists(json, 'target_fork_name') ? undefined : json['target_fork_name'],
        'branchName': !runtime_1.exists(json, 'branch_name') ? undefined : json['branch_name'],
        'githubPrNumber': !runtime_1.exists(json, 'github_pr_number') ? undefined : json['github_pr_number'],
        'githubPrUrl': json['github_pr_url'],
    };
}
exports.WatchedPullRequestFromJSONTyped = WatchedPullRequestFromJSONTyped;
function WatchedPullRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'branch_name': value.branchName,
        'github_pr_url': value.githubPrUrl,
    };
}
exports.WatchedPullRequestToJSON = WatchedPullRequestToJSON;
