"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function TokenVerifyFromJSON(json) {
    return TokenVerifyFromJSONTyped(json, false);
}
exports.TokenVerifyFromJSON = TokenVerifyFromJSON;
function TokenVerifyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
    };
}
exports.TokenVerifyFromJSONTyped = TokenVerifyFromJSONTyped;
function TokenVerifyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
    };
}
exports.TokenVerifyToJSON = TokenVerifyToJSON;
