"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function PasswordTokenFromJSON(json) {
    return PasswordTokenFromJSONTyped(json, false);
}
exports.PasswordTokenFromJSON = PasswordTokenFromJSON;
function PasswordTokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': json['password'],
        'token': json['token'],
    };
}
exports.PasswordTokenFromJSONTyped = PasswordTokenFromJSONTyped;
function PasswordTokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
        'token': value.token,
    };
}
exports.PasswordTokenToJSON = PasswordTokenToJSON;
