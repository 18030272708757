"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function DisplayStaticContentPagesFromJSON(json) {
    return DisplayStaticContentPagesFromJSONTyped(json, false);
}
exports.DisplayStaticContentPagesFromJSON = DisplayStaticContentPagesFromJSON;
function DisplayStaticContentPagesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'about': json['about'],
        'contact': json['contact'],
        'donate': json['donate'],
        'tos': json['tos'],
        'honor': json['honor'],
        'privacy': json['privacy'],
    };
}
exports.DisplayStaticContentPagesFromJSONTyped = DisplayStaticContentPagesFromJSONTyped;
function DisplayStaticContentPagesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'about': value.about,
        'contact': value.contact,
        'donate': value.donate,
        'tos': value.tos,
        'honor': value.honor,
        'privacy': value.privacy,
    };
}
exports.DisplayStaticContentPagesToJSON = DisplayStaticContentPagesToJSON;
