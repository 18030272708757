const messages = {
  notAllowedForStaff: {
    defaultMessage:
      'This page is not accessible by staff users yet. Use <link>this link</link> instead.',
    description: 'Error message for staff users'
  },
  back: {
    defaultMessage: 'Back',
    description: 'Back button to go to last page'
  }
};

export default messages;
