"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
/**
* @export
* @enum {string}
*/
var OpenEdXInstanceConfigDnsConfigurationStateEnum;
(function (OpenEdXInstanceConfigDnsConfigurationStateEnum) {
    OpenEdXInstanceConfigDnsConfigurationStateEnum["Verified"] = "verified";
    OpenEdXInstanceConfigDnsConfigurationStateEnum["Pending"] = "pending";
    OpenEdXInstanceConfigDnsConfigurationStateEnum["Failed"] = "failed";
    OpenEdXInstanceConfigDnsConfigurationStateEnum["NotRequired"] = "not_required";
})(OpenEdXInstanceConfigDnsConfigurationStateEnum = exports.OpenEdXInstanceConfigDnsConfigurationStateEnum || (exports.OpenEdXInstanceConfigDnsConfigurationStateEnum = {}));
function OpenEdXInstanceConfigFromJSON(json) {
    return OpenEdXInstanceConfigFromJSONTyped(json, false);
}
exports.OpenEdXInstanceConfigFromJSON = OpenEdXInstanceConfigFromJSON;
function OpenEdXInstanceConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'lmsUrl': !runtime_1.exists(json, 'lms_url') ? undefined : json['lms_url'],
        'studioUrl': !runtime_1.exists(json, 'studio_url') ? undefined : json['studio_url'],
        'subdomain': json['subdomain'],
        'externalDomain': !runtime_1.exists(json, 'external_domain') ? undefined : json['external_domain'],
        'instanceName': json['instance_name'],
        'publicContactEmail': !runtime_1.exists(json, 'public_contact_email') ? undefined : json['public_contact_email'],
        'privacyPolicyUrl': !runtime_1.exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'useAdvancedTheme': !runtime_1.exists(json, 'use_advanced_theme') ? undefined : json['use_advanced_theme'],
        'draftThemeConfig': !runtime_1.exists(json, 'draft_theme_config') ? undefined : _1.ThemeSchemaFromJSON(json['draft_theme_config']),
        'logo': !runtime_1.exists(json, 'logo') ? undefined : json['logo'],
        'favicon': !runtime_1.exists(json, 'favicon') ? undefined : json['favicon'],
        'heroCoverImage': !runtime_1.exists(json, 'hero_cover_image') ? undefined : json['hero_cover_image'],
        'draftStaticContentOverrides': !runtime_1.exists(json, 'draft_static_content_overrides') ? undefined : _1.StaticContentOverridesFromJSON(json['draft_static_content_overrides']),
        'staticPagesEnabled': !runtime_1.exists(json, 'static_pages_enabled') ? undefined : json['static_pages_enabled'],
        'isEmailVerified': !runtime_1.exists(json, 'is_email_verified') ? undefined : json['is_email_verified'],
        'dnsConfigurationState': !runtime_1.exists(json, 'dns_configuration_state') ? undefined : json['dns_configuration_state'],
        'footerLogoImage': !runtime_1.exists(json, 'footer_logo_image') ? undefined : json['footer_logo_image'],
        'footerLogoUrl': !runtime_1.exists(json, 'footer_logo_url') ? undefined : json['footer_logo_url'],
    };
}
exports.OpenEdXInstanceConfigFromJSONTyped = OpenEdXInstanceConfigFromJSONTyped;
function OpenEdXInstanceConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'subdomain': value.subdomain,
        'external_domain': value.externalDomain,
        'instance_name': value.instanceName,
        'public_contact_email': value.publicContactEmail,
        'privacy_policy_url': value.privacyPolicyUrl,
        'use_advanced_theme': value.useAdvancedTheme,
        'draft_theme_config': _1.ThemeSchemaToJSON(value.draftThemeConfig),
        'draft_static_content_overrides': _1.StaticContentOverridesToJSON(value.draftStaticContentOverrides),
        'footer_logo_url': value.footerLogoUrl,
    };
}
exports.OpenEdXInstanceConfigToJSON = OpenEdXInstanceConfigToJSON;
