"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function ValidationErrorFromJSON(json) {
    return ValidationErrorFromJSONTyped(json, false);
}
exports.ValidationErrorFromJSON = ValidationErrorFromJSON;
function ValidationErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'errors': !runtime_1.exists(json, 'errors') ? undefined : json['errors'],
        'nonFieldErrors': !runtime_1.exists(json, 'non_field_errors') ? undefined : json['non_field_errors'],
    };
}
exports.ValidationErrorFromJSONTyped = ValidationErrorFromJSONTyped;
function ValidationErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'errors': value.errors,
        'non_field_errors': value.nonFieldErrors,
    };
}
exports.ValidationErrorToJSON = ValidationErrorToJSON;
