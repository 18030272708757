"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function SpawnAppServerFromJSON(json) {
    return SpawnAppServerFromJSONTyped(json, false);
}
exports.SpawnAppServerFromJSON = SpawnAppServerFromJSON;
function SpawnAppServerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'instanceId': json['instance_id'],
    };
}
exports.SpawnAppServerFromJSONTyped = SpawnAppServerFromJSONTyped;
function SpawnAppServerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'instance_id': value.instanceId,
    };
}
exports.SpawnAppServerToJSON = SpawnAppServerToJSON;
