"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var runtime = require("../runtime");
var models_1 = require("../models");
/**
 *
 */
var V2Api = /** @class */ (function (_super) {
    __extends(V2Api, _super);
    function V2Api() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Create new user registration
     */
    V2Api.prototype.accountsCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling accountsCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/accounts/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AccountToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccountFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Create new user registration
     */
    V2Api.prototype.accountsCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountsCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Get current user registration data
     */
    V2Api.prototype.accountsListRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/accounts/",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.AccountFromJSON); })];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Get current user registration data
     */
    V2Api.prototype.accountsList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountsListRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Update current user registration data
     */
    V2Api.prototype.accountsPartialUpdateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.username === null || requestParameters.username === undefined) {
                            throw new runtime.RequiredError('username', 'Required parameter requestParameters.username was null or undefined when calling accountsPartialUpdate.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling accountsPartialUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/accounts/{username}/".replace("{" + "username" + "}", encodeURIComponent(String(requestParameters.username))),
                                method: 'PATCH',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AccountToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccountFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Update current user registration data
     */
    V2Api.prototype.accountsPartialUpdate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountsPartialUpdateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Update current user registration data
     */
    V2Api.prototype.accountsUpdateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.username === null || requestParameters.username === undefined) {
                            throw new runtime.RequiredError('username', 'Required parameter requestParameters.username was null or undefined when calling accountsUpdate.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling accountsUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/accounts/{username}/".replace("{" + "username" + "}", encodeURIComponent(String(requestParameters.username))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.AccountToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.AccountFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * User account management API.  This API can be used to register users, and to access user registration information for the current user.
     * Update current user registration data
     */
    V2Api.prototype.accountsUpdate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountsUpdateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Refresh a JWT auth token from refresh token.
     */
    V2Api.prototype.authRefreshCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling authRefreshCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/auth/refresh/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.TokenRefreshToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.JwtTokenFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Refresh a JWT auth token from refresh token.
     */
    V2Api.prototype.authRefreshCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authRefreshCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Generate a JWT auth token from user credentials.
     */
    V2Api.prototype.authTokenCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling authTokenCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/auth/token/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.TokenObtainPairToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.JwtTokenFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Generate a JWT auth token from user credentials.
     */
    V2Api.prototype.authTokenCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authTokenCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Check if a JWT auth token is valid.
     */
    V2Api.prototype.authVerifyCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling authVerifyCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/auth/verify/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.TokenVerifyToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * This overrides the method just to add the Swagger schema overrides
     * Check if a JWT auth token is valid.
     */
    V2Api.prototype.authVerifyCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.authVerifyCreateRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Checks if user is creating account with external domain and fill subdomain slug.  This check if `external_domain` is filled, if so, generate a valid subdomain slug and fill in the field before passing it to the serializer.  Checks if public contact email is empty or not, if empty override it with user mail
     * Create new user instance.
     */
    V2Api.prototype.instancesOpenedxConfigCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OpenEdXInstanceConfigToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OpenEdXInstanceConfigFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Checks if user is creating account with external domain and fill subdomain slug.  This check if `external_domain` is filled, if so, generate a valid subdomain slug and fill in the field before passing it to the serializer.  Checks if public contact email is empty or not, if empty override it with user mail
     * Create new user instance.
     */
    V2Api.prototype.instancesOpenedxConfigCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Send a POST to action image/ with the file in the `logo` or `favicon` field to add or update it.
     * Endpoint for saving favicon or logo images
     */
    V2Api.prototype.instancesOpenedxConfigImageRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, consumes, canConsumeForm, formParams, useForm, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigImage.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        consumes = [
                            { contentType: 'multipart/form-data' },
                        ];
                        canConsumeForm = runtime.canConsumeForm(consumes);
                        useForm = false;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        // use FormData to transmit files using content-type "multipart/form-data"
                        useForm = canConsumeForm;
                        if (useForm) {
                            formParams = new FormData();
                        }
                        else {
                            formParams = new URLSearchParams();
                        }
                        if (requestParameters.logo !== undefined) {
                            formParams.append('logo', requestParameters.logo);
                        }
                        if (requestParameters.favicon !== undefined) {
                            formParams.append('favicon', requestParameters.favicon);
                        }
                        if (requestParameters.heroCoverImage !== undefined) {
                            formParams.append('hero_cover_image', requestParameters.heroCoverImage);
                        }
                        if (requestParameters.footerLogoImage !== undefined) {
                            formParams.append('footer_logo_image', requestParameters.footerLogoImage);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/image/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: formParams,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ApplicationImageUploadFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Send a POST to action image/ with the file in the `logo` or `favicon` field to add or update it.
     * Endpoint for saving favicon or logo images
     */
    V2Api.prototype.instancesOpenedxConfigImage = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigImageRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Endpoint for getting the list of instances owned by the current user.  If the user is staff, return a 400 error. This is used by the frontend to prevent staff users from accidentally accessing and updating other users\' instances (see SE-2865).
     * Get all instances owned by user
     */
    V2Api.prototype.instancesOpenedxConfigListRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.OpenEdXInstanceConfigFromJSON); })];
                }
            });
        });
    };
    /**
     * Endpoint for getting the list of instances owned by the current user.  If the user is staff, return a 400 error. This is used by the frontend to prevent staff users from accidentally accessing and updating other users\' instances (see SE-2865).
     * Get all instances owned by user
     */
    V2Api.prototype.instancesOpenedxConfigList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigListRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This API can be used to manage the configuration for Open edX instances owned by clients.
     * Open edX Instance Configuration API.
     */
    V2Api.prototype.instancesOpenedxConfigPartialUpdateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigPartialUpdate.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigPartialUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'PATCH',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OpenEdXInstanceConfigUpdateToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OpenEdXInstanceConfigUpdateFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * This API can be used to manage the configuration for Open edX instances owned by clients.
     * Open edX Instance Configuration API.
     */
    V2Api.prototype.instancesOpenedxConfigPartialUpdate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigPartialUpdateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Open edX Instance Configuration API.  This API can be used to manage the configuration for Open edX instances owned by clients.
     * Get an instance owned by user
     */
    V2Api.prototype.instancesOpenedxConfigReadRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigRead.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OpenEdXInstanceConfigFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Open edX Instance Configuration API.  This API can be used to manage the configuration for Open edX instances owned by clients.
     * Get an instance owned by user
     */
    V2Api.prototype.instancesOpenedxConfigRead = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigReadRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Partial update for static content overrides configuration
     */
    V2Api.prototype.instancesOpenedxConfigStaticContentOverridesRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigStaticContentOverrides.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigStaticContentOverrides.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/static_content_overrides/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'PATCH',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.StaticContentOverridesToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.StaticContentOverridesFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Partial update for static content overrides configuration
     */
    V2Api.prototype.instancesOpenedxConfigStaticContentOverrides = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigStaticContentOverridesRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This is a custom handler to partially update theme fields.
     * Partial update for theme configuration
     */
    V2Api.prototype.instancesOpenedxConfigThemeConfigRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigThemeConfig.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigThemeConfig.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/theme_config/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'PATCH',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.ThemeSchemaToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.ThemeSchemaFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * This is a custom handler to partially update theme fields.
     * Partial update for theme configuration
     */
    V2Api.prototype.instancesOpenedxConfigThemeConfig = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigThemeConfigRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Enable/Disable static page.
     */
    V2Api.prototype.instancesOpenedxConfigToggleStaticContentPageRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigToggleStaticContentPage.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigToggleStaticContentPage.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/toggle_static_content_page/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.ToggleStaticContentPagesToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.DisplayStaticContentPagesFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Enable/Disable static page.
     */
    V2Api.prototype.instancesOpenedxConfigToggleStaticContentPage = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigToggleStaticContentPageRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Open edX Instance Configuration API.  This API can be used to manage the configuration for Open edX instances owned by clients.
     * Update instance owned by user
     */
    V2Api.prototype.instancesOpenedxConfigUpdateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxConfigUpdate.');
                        }
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigUpdate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'PUT',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OpenEdXInstanceConfigToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OpenEdXInstanceConfigFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Open edX Instance Configuration API.  This API can be used to manage the configuration for Open edX instances owned by clients.
     * Update instance owned by user
     */
    V2Api.prototype.instancesOpenedxConfigUpdate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigUpdateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This action is publicly accessible and allows any user to validate an instance configuration. It is useful when signing up.
     * Validate instance configuration
     */
    V2Api.prototype.instancesOpenedxConfigValidateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxConfigValidate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_config/validate/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OpenEdXInstanceConfigToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * This action is publicly accessible and allows any user to validate an instance configuration. It is useful when signing up.
     * Validate instance configuration
     */
    V2Api.prototype.instancesOpenedxConfigValidate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxConfigValidateRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * This API call will copy over any changes made to the instance config to the actual instance used to launch AppServers and launch a new AppServer with the applied changes.  It checks if an AppServer is already being provisioned and in that case prevents a new one from being launched unless forced.
     * Commit configuration changes to instance and launch new AppServer.
     */
    V2Api.prototype.instancesOpenedxDeploymentCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling instancesOpenedxDeploymentCreate.');
                        }
                        queryParameters = {};
                        if (requestParameters.cancelPendingDeployments !== undefined) {
                            queryParameters['cancel_pending_deployments'] = requestParameters.cancelPendingDeployments;
                        }
                        if (requestParameters.force !== undefined) {
                            queryParameters['force'] = requestParameters.force;
                        }
                        if (requestParameters.deploymentType !== undefined) {
                            queryParameters['deployment_type'] = requestParameters.deploymentType;
                        }
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_deployment/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.OpenEdXInstanceDeploymentCreateToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * This API call will copy over any changes made to the instance config to the actual instance used to launch AppServers and launch a new AppServer with the applied changes.  It checks if an AppServer is already being provisioned and in that case prevents a new one from being launched unless forced.
     * Commit configuration changes to instance and launch new AppServer.
     */
    V2Api.prototype.instancesOpenedxDeploymentCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxDeploymentCreateRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * This allows the user to cancel an ongoing deployment, note that this can can cancel both user-triggered deployments and OpenCraft triggered deployments.
     * Stops all current redeployments.
     */
    V2Api.prototype.instancesOpenedxDeploymentDeleteRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxDeploymentDelete.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_deployment/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'DELETE',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.VoidApiResponse(response)];
                }
            });
        });
    };
    /**
     * This allows the user to cancel an ongoing deployment, note that this can can cancel both user-triggered deployments and OpenCraft triggered deployments.
     * Stops all current redeployments.
     */
    V2Api.prototype.instancesOpenedxDeploymentDelete = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxDeploymentDeleteRaw(requestParameters)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * List method not allowed.
     */
    V2Api.prototype.instancesOpenedxDeploymentListRaw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_deployment/",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.OpenEdXInstanceDeploymentStatusFromJSON); })];
                }
            });
        });
    };
    /**
     * List method not allowed.
     */
    V2Api.prototype.instancesOpenedxDeploymentList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxDeploymentListRaw()];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * This API will check for provisioning appservers or changes in settings that need to be deployed and return a status code to the frontend.
     * Retrieves the deployment status for a given betatest instance.
     */
    V2Api.prototype.instancesOpenedxDeploymentReadRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling instancesOpenedxDeploymentRead.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/instances/openedx_deployment/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.OpenEdXInstanceDeploymentStatusFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * This API will check for provisioning appservers or changes in settings that need to be deployed and return a status code to the frontend.
     * Retrieves the deployment status for a given betatest instance.
     */
    V2Api.prototype.instancesOpenedxDeploymentRead = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.instancesOpenedxDeploymentReadRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * If there is no deployments at all, this endpoint returns blank notification, that deployment is preparing.
     * Returns Open edX deployments information in form of status notifications.
     */
    V2Api.prototype.notificationsListRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        queryParameters = {};
                        if (requestParameters.limit !== undefined) {
                            queryParameters['limit'] = requestParameters.limit;
                        }
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/notifications/",
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return jsonValue.map(models_1.OpenEdXInstanceDeploymentNotificationFromJSON); })];
                }
            });
        });
    };
    /**
     * If there is no deployments at all, this endpoint returns blank notification, that deployment is preparing.
     * Returns Open edX deployments information in form of status notifications.
     */
    V2Api.prototype.notificationsList = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationsListRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * An Api View which provides a method to reset a password based on a unique token
     */
    V2Api.prototype.passwordResetConfirmCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling passwordResetConfirmCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/password_reset/confirm/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.PasswordTokenToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.PasswordTokenFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * An Api View which provides a method to reset a password based on a unique token
     */
    V2Api.prototype.passwordResetConfirmCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.passwordResetConfirmCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Sends a signal reset_password_token_created when a reset token was created
     * An Api View which provides a method to request a password reset token based on an e-mail address
     */
    V2Api.prototype.passwordResetCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling passwordResetCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/password_reset/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.EmailToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.EmailFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * Sends a signal reset_password_token_created when a reset token was created
     * An Api View which provides a method to request a password reset token based on an e-mail address
     */
    V2Api.prototype.passwordResetCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.passwordResetCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * An Api View which provides a method to verify that a token is valid
     */
    V2Api.prototype.passwordResetValidateTokenCreateRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.data === null || requestParameters.data === undefined) {
                            throw new runtime.RequiredError('data', 'Required parameter requestParameters.data was null or undefined when calling passwordResetValidateTokenCreate.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        headerParameters['Content-Type'] = 'application/json';
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/password_reset/validate_token/",
                                method: 'POST',
                                headers: headerParameters,
                                query: queryParameters,
                                body: models_1.TokenToJSON(requestParameters.data),
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) { return models_1.TokenFromJSON(jsonValue); })];
                }
            });
        });
    };
    /**
     * An Api View which provides a method to verify that a token is valid
     */
    V2Api.prototype.passwordResetValidateTokenCreate = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.passwordResetValidateTokenCreateRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Checks if the verification code is valid and then confirms the user email address. Note that the user can use the same activation code multiple times since the library we\'re using doesn\'t expire the verification codes after confirming.
     * Confirms a user\'s email address.
     */
    V2Api.prototype.verifyEmailReadRaw = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var queryParameters, headerParameters, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (requestParameters.id === null || requestParameters.id === undefined) {
                            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling verifyEmailRead.');
                        }
                        queryParameters = {};
                        headerParameters = {};
                        if (this.configuration && this.configuration.apiKey) {
                            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // api_key authentication
                        }
                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        return [4 /*yield*/, this.request({
                                path: "/v2/verify_email/{id}/".replace("{" + "id" + "}", encodeURIComponent(String(requestParameters.id))),
                                method: 'GET',
                                headers: headerParameters,
                                query: queryParameters,
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, new runtime.JSONApiResponse(response)];
                }
            });
        });
    };
    /**
     * Checks if the verification code is valid and then confirms the user email address. Note that the user can use the same activation code multiple times since the library we\'re using doesn\'t expire the verification codes after confirming.
     * Confirms a user\'s email address.
     */
    V2Api.prototype.verifyEmailRead = function (requestParameters) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.verifyEmailReadRaw(requestParameters)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.value()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return V2Api;
}(runtime.BaseAPI));
exports.V2Api = V2Api;
/**
    * @export
    * @enum {string}
    */
var InstancesOpenedxDeploymentCreateDeploymentTypeEnum;
(function (InstancesOpenedxDeploymentCreateDeploymentTypeEnum) {
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["User"] = "user";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Batch"] = "batch";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Admin"] = "admin";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Pr"] = "pr";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Periodic"] = "periodic";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Registration"] = "registration";
    InstancesOpenedxDeploymentCreateDeploymentTypeEnum["Unknown"] = "unknown";
})(InstancesOpenedxDeploymentCreateDeploymentTypeEnum = exports.InstancesOpenedxDeploymentCreateDeploymentTypeEnum || (exports.InstancesOpenedxDeploymentCreateDeploymentTypeEnum = {}));
