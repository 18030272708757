"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
function TokenObtainPairFromJSON(json) {
    return TokenObtainPairFromJSONTyped(json, false);
}
exports.TokenObtainPairFromJSON = TokenObtainPairFromJSON;
function TokenObtainPairFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': json['username'],
        'password': json['password'],
    };
}
exports.TokenObtainPairFromJSONTyped = TokenObtainPairFromJSONTyped;
function TokenObtainPairToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'password': value.password,
    };
}
exports.TokenObtainPairToJSON = TokenObtainPairToJSON;
