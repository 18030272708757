"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
var _1 = require("./");
function OpenEdXAppServerFromJSON(json) {
    return OpenEdXAppServerFromJSONTyped(json, false);
}
exports.OpenEdXAppServerFromJSON = OpenEdXAppServerFromJSON;
function OpenEdXAppServerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'privacyPolicyUrl': !runtime_1.exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'openedxRelease': !runtime_1.exists(json, 'openedx_release') ? undefined : json['openedx_release'],
        'configurationSourceRepoUrl': !runtime_1.exists(json, 'configuration_source_repo_url') ? undefined : json['configuration_source_repo_url'],
        'configurationVersion': !runtime_1.exists(json, 'configuration_version') ? undefined : json['configuration_version'],
        'configurationExtraSettings': !runtime_1.exists(json, 'configuration_extra_settings') ? undefined : json['configuration_extra_settings'],
        'configurationPlaybookName': !runtime_1.exists(json, 'configuration_playbook_name') ? undefined : json['configuration_playbook_name'],
        'edxPlatformRepositoryUrl': !runtime_1.exists(json, 'edx_platform_repository_url') ? undefined : json['edx_platform_repository_url'],
        'edxPlatformCommit': json['edx_platform_commit'],
        'ansibleAppserverRepoUrl': !runtime_1.exists(json, 'ansible_appserver_repo_url') ? undefined : json['ansible_appserver_repo_url'],
        'ansibleAppserverPlaybook': !runtime_1.exists(json, 'ansible_appserver_playbook') ? undefined : json['ansible_appserver_playbook'],
        'ansibleAppserverRequirementsPath': !runtime_1.exists(json, 'ansible_appserver_requirements_path') ? undefined : json['ansible_appserver_requirements_path'],
        'ansibleAppserverVersion': !runtime_1.exists(json, 'ansible_appserver_version') ? undefined : json['ansible_appserver_version'],
        'openstackServerFlavor': !runtime_1.exists(json, 'openstack_server_flavor') ? undefined : json['openstack_server_flavor'],
        'openstackServerBaseImage': !runtime_1.exists(json, 'openstack_server_base_image') ? undefined : json['openstack_server_base_image'],
        'openstackServerSshKeyname': !runtime_1.exists(json, 'openstack_server_ssh_keyname') ? undefined : json['openstack_server_ssh_keyname'],
        'additionalSecurityGroups': !runtime_1.exists(json, 'additional_security_groups') ? undefined : json['additional_security_groups'],
        'additionalMonitoringEmails': !runtime_1.exists(json, 'additional_monitoring_emails') ? undefined : json['additional_monitoring_emails'],
        'provisioningFailureNotificationEmails': !runtime_1.exists(json, 'provisioning_failure_notification_emails') ? undefined : json['provisioning_failure_notification_emails'],
        'openedxAppserverCount': !runtime_1.exists(json, 'openedx_appserver_count') ? undefined : json['openedx_appserver_count'],
        'configurationDatabaseSettings': !runtime_1.exists(json, 'configuration_database_settings') ? undefined : json['configuration_database_settings'],
        'configurationStorageSettings': !runtime_1.exists(json, 'configuration_storage_settings') ? undefined : json['configuration_storage_settings'],
        'configurationThemeSettings': !runtime_1.exists(json, 'configuration_theme_settings') ? undefined : json['configuration_theme_settings'],
        'configurationSiteConfigurationSettings': !runtime_1.exists(json, 'configuration_site_configuration_settings') ? undefined : json['configuration_site_configuration_settings'],
        'commonConfigurationSettings': !runtime_1.exists(json, 'common_configuration_settings') ? undefined : json['common_configuration_settings'],
        'configurationSettings': json['configuration_settings'],
        'instance': _1.InstanceReferenceMinimalFromJSON(json['instance']),
        'server': _1.OpenStackServerFromJSON(json['server']),
    };
}
exports.OpenEdXAppServerFromJSONTyped = OpenEdXAppServerFromJSONTyped;
function OpenEdXAppServerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'privacy_policy_url': value.privacyPolicyUrl,
        'openedx_release': value.openedxRelease,
        'configuration_source_repo_url': value.configurationSourceRepoUrl,
        'configuration_version': value.configurationVersion,
        'configuration_extra_settings': value.configurationExtraSettings,
        'configuration_playbook_name': value.configurationPlaybookName,
        'edx_platform_repository_url': value.edxPlatformRepositoryUrl,
        'edx_platform_commit': value.edxPlatformCommit,
        'ansible_appserver_repo_url': value.ansibleAppserverRepoUrl,
        'ansible_appserver_playbook': value.ansibleAppserverPlaybook,
        'ansible_appserver_requirements_path': value.ansibleAppserverRequirementsPath,
        'ansible_appserver_version': value.ansibleAppserverVersion,
        'openstack_server_flavor': value.openstackServerFlavor,
        'openstack_server_base_image': value.openstackServerBaseImage,
        'openstack_server_ssh_keyname': value.openstackServerSshKeyname,
        'additional_security_groups': value.additionalSecurityGroups,
        'additional_monitoring_emails': value.additionalMonitoringEmails,
        'provisioning_failure_notification_emails': value.provisioningFailureNotificationEmails,
        'openedx_appserver_count': value.openedxAppserverCount,
        'configuration_database_settings': value.configurationDatabaseSettings,
        'configuration_storage_settings': value.configurationStorageSettings,
        'configuration_theme_settings': value.configurationThemeSettings,
        'configuration_site_configuration_settings': value.configurationSiteConfigurationSettings,
        'common_configuration_settings': value.commonConfigurationSettings,
        'configuration_settings': value.configurationSettings,
        'instance': _1.InstanceReferenceMinimalToJSON(value.instance),
        'server': _1.OpenStackServerToJSON(value.server),
    };
}
exports.OpenEdXAppServerToJSON = OpenEdXAppServerToJSON;
