"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function StaticContentOverridesFromJSON(json) {
    return StaticContentOverridesFromJSONTyped(json, false);
}
exports.StaticContentOverridesFromJSON = StaticContentOverridesFromJSON;
function StaticContentOverridesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !runtime_1.exists(json, 'version') ? undefined : json['version'],
        'staticTemplateAboutHeader': !runtime_1.exists(json, 'static_template_about_header') ? undefined : json['static_template_about_header'],
        'staticTemplateAboutContent': !runtime_1.exists(json, 'static_template_about_content') ? undefined : json['static_template_about_content'],
        'staticTemplateContactHeader': !runtime_1.exists(json, 'static_template_contact_header') ? undefined : json['static_template_contact_header'],
        'staticTemplateContactContent': !runtime_1.exists(json, 'static_template_contact_content') ? undefined : json['static_template_contact_content'],
        'staticTemplateDonateHeader': !runtime_1.exists(json, 'static_template_donate_header') ? undefined : json['static_template_donate_header'],
        'staticTemplateDonateContent': !runtime_1.exists(json, 'static_template_donate_content') ? undefined : json['static_template_donate_content'],
        'staticTemplateTosHeader': !runtime_1.exists(json, 'static_template_tos_header') ? undefined : json['static_template_tos_header'],
        'staticTemplateTosContent': !runtime_1.exists(json, 'static_template_tos_content') ? undefined : json['static_template_tos_content'],
        'staticTemplateHonorHeader': !runtime_1.exists(json, 'static_template_honor_header') ? undefined : json['static_template_honor_header'],
        'staticTemplateHonorContent': !runtime_1.exists(json, 'static_template_honor_content') ? undefined : json['static_template_honor_content'],
        'staticTemplatePrivacyHeader': !runtime_1.exists(json, 'static_template_privacy_header') ? undefined : json['static_template_privacy_header'],
        'staticTemplatePrivacyContent': !runtime_1.exists(json, 'static_template_privacy_content') ? undefined : json['static_template_privacy_content'],
        'homepageOverlayHtml': !runtime_1.exists(json, 'homepage_overlay_html') ? undefined : json['homepage_overlay_html'],
    };
}
exports.StaticContentOverridesFromJSONTyped = StaticContentOverridesFromJSONTyped;
function StaticContentOverridesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'static_template_about_header': value.staticTemplateAboutHeader,
        'static_template_about_content': value.staticTemplateAboutContent,
        'static_template_contact_header': value.staticTemplateContactHeader,
        'static_template_contact_content': value.staticTemplateContactContent,
        'static_template_donate_header': value.staticTemplateDonateHeader,
        'static_template_donate_content': value.staticTemplateDonateContent,
        'static_template_tos_header': value.staticTemplateTosHeader,
        'static_template_tos_content': value.staticTemplateTosContent,
        'static_template_honor_header': value.staticTemplateHonorHeader,
        'static_template_honor_content': value.staticTemplateHonorContent,
        'static_template_privacy_header': value.staticTemplatePrivacyHeader,
        'static_template_privacy_content': value.staticTemplatePrivacyContent,
        'homepage_overlay_html': value.homepageOverlayHtml,
    };
}
exports.StaticContentOverridesToJSON = StaticContentOverridesToJSON;
