"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
* @export
* @enum {string}
*/
var OpenEdXInstanceDeploymentNotificationStatusEnum;
(function (OpenEdXInstanceDeploymentNotificationStatusEnum) {
    OpenEdXInstanceDeploymentNotificationStatusEnum["Healthy"] = "healthy";
    OpenEdXInstanceDeploymentNotificationStatusEnum["Unhealthy"] = "unhealthy";
    OpenEdXInstanceDeploymentNotificationStatusEnum["Offline"] = "offline";
    OpenEdXInstanceDeploymentNotificationStatusEnum["Provisioning"] = "provisioning";
    OpenEdXInstanceDeploymentNotificationStatusEnum["Preparing"] = "preparing";
    OpenEdXInstanceDeploymentNotificationStatusEnum["ChangesPending"] = "changes_pending";
})(OpenEdXInstanceDeploymentNotificationStatusEnum = exports.OpenEdXInstanceDeploymentNotificationStatusEnum || (exports.OpenEdXInstanceDeploymentNotificationStatusEnum = {}));
function OpenEdXInstanceDeploymentNotificationFromJSON(json) {
    return OpenEdXInstanceDeploymentNotificationFromJSONTyped(json, false);
}
exports.OpenEdXInstanceDeploymentNotificationFromJSON = OpenEdXInstanceDeploymentNotificationFromJSON;
function OpenEdXInstanceDeploymentNotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': json['status'],
        'deployedChanges': json['deployed_changes'],
        'date': (new Date(json['date'])),
    };
}
exports.OpenEdXInstanceDeploymentNotificationFromJSONTyped = OpenEdXInstanceDeploymentNotificationFromJSONTyped;
function OpenEdXInstanceDeploymentNotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
        'deployed_changes': value.deployedChanges,
        'date': (value.date.toISOString()),
    };
}
exports.OpenEdXInstanceDeploymentNotificationToJSON = OpenEdXInstanceDeploymentNotificationToJSON;
