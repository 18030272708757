"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function AccountFromJSON(json) {
    return AccountFromJSONTyped(json, false);
}
exports.AccountFromJSON = AccountFromJSON;
function AccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fullName': json['full_name'],
        'username': json['username'],
        'password': json['password'],
        'email': json['email'],
        'acceptedPrivacyPolicy': (json['accepted_privacy_policy'] === null ? null : new Date(json['accepted_privacy_policy'])),
        'acceptDomainCondition': json['accept_domain_condition'],
        'subscribeToUpdates': !runtime_1.exists(json, 'subscribe_to_updates') ? undefined : json['subscribe_to_updates'],
    };
}
exports.AccountFromJSONTyped = AccountFromJSONTyped;
function AccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'full_name': value.fullName,
        'username': value.username,
        'password': value.password,
        'email': value.email,
        'accepted_privacy_policy': (value.acceptedPrivacyPolicy === null ? null : value.acceptedPrivacyPolicy.toISOString()),
        'accept_domain_condition': value.acceptDomainCondition,
        'subscribe_to_updates': value.subscribeToUpdates,
    };
}
exports.AccountToJSON = AccountToJSON;
