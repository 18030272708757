"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function InstanceReferenceDetailedFromJSON(json) {
    return InstanceReferenceDetailedFromJSONTyped(json, false);
}
exports.InstanceReferenceDetailedFromJSON = InstanceReferenceDetailedFromJSON;
function InstanceReferenceDetailedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'apiUrl': !runtime_1.exists(json, 'api_url') ? undefined : json['api_url'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'created': !runtime_1.exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !runtime_1.exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'isArchived': !runtime_1.exists(json, 'is_archived') ? undefined : json['is_archived'],
        'logsUrl': !runtime_1.exists(json, 'logs_url') ? undefined : json['logs_url'],
        'appserversFullListUrl': !runtime_1.exists(json, 'appservers_full_list_url') ? undefined : json['appservers_full_list_url'],
    };
}
exports.InstanceReferenceDetailedFromJSONTyped = InstanceReferenceDetailedFromJSONTyped;
function InstanceReferenceDetailedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'is_archived': value.isArchived,
    };
}
exports.InstanceReferenceDetailedToJSON = InstanceReferenceDetailedToJSON;
