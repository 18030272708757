"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function ThemeSchemaFromJSON(json) {
    return ThemeSchemaFromJSONTyped(json, false);
}
exports.ThemeSchemaFromJSON = ThemeSchemaFromJSON;
function ThemeSchemaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'version': !runtime_1.exists(json, 'version') ? undefined : json['version'],
        'mainColor': !runtime_1.exists(json, 'main-color') ? undefined : json['main-color'],
        'linkColor': !runtime_1.exists(json, 'link-color') ? undefined : json['link-color'],
        'headerBg': !runtime_1.exists(json, 'header-bg') ? undefined : json['header-bg'],
        'footerBg': !runtime_1.exists(json, 'footer-bg') ? undefined : json['footer-bg'],
        'footerColor': !runtime_1.exists(json, 'footer-color') ? undefined : json['footer-color'],
        'footerLinkColor': !runtime_1.exists(json, 'footer-link-color') ? undefined : json['footer-link-color'],
        'btnPrimaryBg': !runtime_1.exists(json, 'btn-primary-bg') ? undefined : json['btn-primary-bg'],
        'btnPrimaryColor': !runtime_1.exists(json, 'btn-primary-color') ? undefined : json['btn-primary-color'],
        'btnPrimaryBorderColor': !runtime_1.exists(json, 'btn-primary-border-color') ? undefined : json['btn-primary-border-color'],
        'btnPrimaryHoverBg': !runtime_1.exists(json, 'btn-primary-hover-bg') ? undefined : json['btn-primary-hover-bg'],
        'btnPrimaryHoverColor': !runtime_1.exists(json, 'btn-primary-hover-color') ? undefined : json['btn-primary-hover-color'],
        'btnPrimaryHoverBorderColor': !runtime_1.exists(json, 'btn-primary-hover-border-color') ? undefined : json['btn-primary-hover-border-color'],
        'btnSecondaryBg': !runtime_1.exists(json, 'btn-secondary-bg') ? undefined : json['btn-secondary-bg'],
        'btnSecondaryColor': !runtime_1.exists(json, 'btn-secondary-color') ? undefined : json['btn-secondary-color'],
        'btnSecondaryBorderColor': !runtime_1.exists(json, 'btn-secondary-border-color') ? undefined : json['btn-secondary-border-color'],
        'btnSecondaryHoverBg': !runtime_1.exists(json, 'btn-secondary-hover-bg') ? undefined : json['btn-secondary-hover-bg'],
        'btnSecondaryHoverColor': !runtime_1.exists(json, 'btn-secondary-hover-color') ? undefined : json['btn-secondary-hover-color'],
        'btnSecondaryHoverBorderColor': !runtime_1.exists(json, 'btn-secondary-hover-border-color') ? undefined : json['btn-secondary-hover-border-color'],
        'accentColor': !runtime_1.exists(json, 'accent-color') ? undefined : json['accent-color'],
        'homePageHeroTitleColor': !runtime_1.exists(json, 'home-page-hero-title-color') ? undefined : json['home-page-hero-title-color'],
        'homePageHeroSubtitleColor': !runtime_1.exists(json, 'home-page-hero-subtitle-color') ? undefined : json['home-page-hero-subtitle-color'],
        'customizeSignInBtn': !runtime_1.exists(json, 'customize-sign-in-btn') ? undefined : json['customize-sign-in-btn'],
        'btnSignInBg': !runtime_1.exists(json, 'btn-sign-in-bg') ? undefined : json['btn-sign-in-bg'],
        'btnSignInColor': !runtime_1.exists(json, 'btn-sign-in-color') ? undefined : json['btn-sign-in-color'],
        'btnSignInBorderColor': !runtime_1.exists(json, 'btn-sign-in-border-color') ? undefined : json['btn-sign-in-border-color'],
        'btnSignInHoverBg': !runtime_1.exists(json, 'btn-sign-in-hover-bg') ? undefined : json['btn-sign-in-hover-bg'],
        'btnSignInHoverColor': !runtime_1.exists(json, 'btn-sign-in-hover-color') ? undefined : json['btn-sign-in-hover-color'],
        'btnSignInHoverBorderColor': !runtime_1.exists(json, 'btn-sign-in-hover-border-color') ? undefined : json['btn-sign-in-hover-border-color'],
        'customizeRegisterBtn': !runtime_1.exists(json, 'customize-register-btn') ? undefined : json['customize-register-btn'],
        'btnRegisterBg': !runtime_1.exists(json, 'btn-register-bg') ? undefined : json['btn-register-bg'],
        'btnRegisterColor': !runtime_1.exists(json, 'btn-register-color') ? undefined : json['btn-register-color'],
        'btnRegisterBorderColor': !runtime_1.exists(json, 'btn-register-border-color') ? undefined : json['btn-register-border-color'],
        'btnRegisterHoverBg': !runtime_1.exists(json, 'btn-register-hover-bg') ? undefined : json['btn-register-hover-bg'],
        'btnRegisterHoverColor': !runtime_1.exists(json, 'btn-register-hover-color') ? undefined : json['btn-register-hover-color'],
        'btnRegisterHoverBorderColor': !runtime_1.exists(json, 'btn-register-hover-border-color') ? undefined : json['btn-register-hover-border-color'],
        'customizeLogistrationActionBtn': !runtime_1.exists(json, 'customize-logistration-action-btn') ? undefined : json['customize-logistration-action-btn'],
        'btnLogistrationBg': !runtime_1.exists(json, 'btn-logistration-bg') ? undefined : json['btn-logistration-bg'],
        'btnLogistrationColor': !runtime_1.exists(json, 'btn-logistration-color') ? undefined : json['btn-logistration-color'],
        'btnLogistrationBorderColor': !runtime_1.exists(json, 'btn-logistration-border-color') ? undefined : json['btn-logistration-border-color'],
        'btnLogistrationHoverBg': !runtime_1.exists(json, 'btn-logistration-hover-bg') ? undefined : json['btn-logistration-hover-bg'],
        'btnLogistrationHoverColor': !runtime_1.exists(json, 'btn-logistration-hover-color') ? undefined : json['btn-logistration-hover-color'],
        'btnLogistrationHoverBorderColor': !runtime_1.exists(json, 'btn-logistration-hover-border-color') ? undefined : json['btn-logistration-hover-border-color'],
        'loginRegisterHeaderColor': !runtime_1.exists(json, 'login-register-header-color') ? undefined : json['login-register-header-color'],
        'mainNavColor': !runtime_1.exists(json, 'main-nav-color') ? undefined : json['main-nav-color'],
        'mainNavLinkColor': !runtime_1.exists(json, 'main-nav-link-color') ? undefined : json['main-nav-link-color'],
        'mainNavItemBorderBottomColor': !runtime_1.exists(json, 'main-nav-item-border-bottom-color') ? undefined : json['main-nav-item-border-bottom-color'],
        'mainNavItemHoverBorderBottomColor': !runtime_1.exists(json, 'main-nav-item-hover-border-bottom-color') ? undefined : json['main-nav-item-hover-border-bottom-color'],
        'userDropdownColor': !runtime_1.exists(json, 'user-dropdown-color') ? undefined : json['user-dropdown-color'],
        'wrapperPreviewMenuColor': !runtime_1.exists(json, 'wrapper-preview-menu-color') ? undefined : json['wrapper-preview-menu-color'],
        'courseNavMenuBorderBottomColor': !runtime_1.exists(json, 'course-nav-menu-border-bottom-color') ? undefined : json['course-nav-menu-border-bottom-color'],
        'accountSettingsNavBorderBottomColor': !runtime_1.exists(json, 'account-settings-nav-border-bottom-color') ? undefined : json['account-settings-nav-border-bottom-color'],
        'accountSettingsNavHoverBorderBottomColor': !runtime_1.exists(json, 'account-settings-nav-hover-border-bottom-color') ? undefined : json['account-settings-nav-hover-border-bottom-color'],
        'staticTemplateFooterUrl': !runtime_1.exists(json, 'static_template_footer_url') ? undefined : json['static_template_footer_url'],
        'staticTemplateFooterImageSrc': !runtime_1.exists(json, 'static_template_footer_image_src') ? undefined : json['static_template_footer_image_src'],
    };
}
exports.ThemeSchemaFromJSONTyped = ThemeSchemaFromJSONTyped;
function ThemeSchemaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'version': value.version,
        'main-color': value.mainColor,
        'link-color': value.linkColor,
        'header-bg': value.headerBg,
        'footer-bg': value.footerBg,
        'footer-color': value.footerColor,
        'footer-link-color': value.footerLinkColor,
        'btn-primary-bg': value.btnPrimaryBg,
        'btn-primary-color': value.btnPrimaryColor,
        'btn-primary-border-color': value.btnPrimaryBorderColor,
        'btn-primary-hover-bg': value.btnPrimaryHoverBg,
        'btn-primary-hover-color': value.btnPrimaryHoverColor,
        'btn-primary-hover-border-color': value.btnPrimaryHoverBorderColor,
        'btn-secondary-bg': value.btnSecondaryBg,
        'btn-secondary-color': value.btnSecondaryColor,
        'btn-secondary-border-color': value.btnSecondaryBorderColor,
        'btn-secondary-hover-bg': value.btnSecondaryHoverBg,
        'btn-secondary-hover-color': value.btnSecondaryHoverColor,
        'btn-secondary-hover-border-color': value.btnSecondaryHoverBorderColor,
        'accent-color': value.accentColor,
        'home-page-hero-title-color': value.homePageHeroTitleColor,
        'home-page-hero-subtitle-color': value.homePageHeroSubtitleColor,
        'customize-sign-in-btn': value.customizeSignInBtn,
        'btn-sign-in-bg': value.btnSignInBg,
        'btn-sign-in-color': value.btnSignInColor,
        'btn-sign-in-border-color': value.btnSignInBorderColor,
        'btn-sign-in-hover-bg': value.btnSignInHoverBg,
        'btn-sign-in-hover-color': value.btnSignInHoverColor,
        'btn-sign-in-hover-border-color': value.btnSignInHoverBorderColor,
        'customize-register-btn': value.customizeRegisterBtn,
        'btn-register-bg': value.btnRegisterBg,
        'btn-register-color': value.btnRegisterColor,
        'btn-register-border-color': value.btnRegisterBorderColor,
        'btn-register-hover-bg': value.btnRegisterHoverBg,
        'btn-register-hover-color': value.btnRegisterHoverColor,
        'btn-register-hover-border-color': value.btnRegisterHoverBorderColor,
        'customize-logistration-action-btn': value.customizeLogistrationActionBtn,
        'btn-logistration-bg': value.btnLogistrationBg,
        'btn-logistration-color': value.btnLogistrationColor,
        'btn-logistration-border-color': value.btnLogistrationBorderColor,
        'btn-logistration-hover-bg': value.btnLogistrationHoverBg,
        'btn-logistration-hover-color': value.btnLogistrationHoverColor,
        'btn-logistration-hover-border-color': value.btnLogistrationHoverBorderColor,
        'login-register-header-color': value.loginRegisterHeaderColor,
        'main-nav-color': value.mainNavColor,
        'main-nav-link-color': value.mainNavLinkColor,
        'main-nav-item-border-bottom-color': value.mainNavItemBorderBottomColor,
        'main-nav-item-hover-border-bottom-color': value.mainNavItemHoverBorderBottomColor,
        'user-dropdown-color': value.userDropdownColor,
        'wrapper-preview-menu-color': value.wrapperPreviewMenuColor,
        'course-nav-menu-border-bottom-color': value.courseNavMenuBorderBottomColor,
        'account-settings-nav-border-bottom-color': value.accountSettingsNavBorderBottomColor,
        'account-settings-nav-hover-border-bottom-color': value.accountSettingsNavHoverBorderBottomColor,
        'static_template_footer_url': value.staticTemplateFooterUrl,
        'static_template_footer_image_src': value.staticTemplateFooterImageSrc,
    };
}
exports.ThemeSchemaToJSON = ThemeSchemaToJSON;
