"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
* @export
* @enum {string}
*/
var OpenEdXInstanceDeploymentStatusStatusEnum;
(function (OpenEdXInstanceDeploymentStatusStatusEnum) {
    OpenEdXInstanceDeploymentStatusStatusEnum["Healthy"] = "healthy";
    OpenEdXInstanceDeploymentStatusStatusEnum["Unhealthy"] = "unhealthy";
    OpenEdXInstanceDeploymentStatusStatusEnum["Offline"] = "offline";
    OpenEdXInstanceDeploymentStatusStatusEnum["Provisioning"] = "provisioning";
    OpenEdXInstanceDeploymentStatusStatusEnum["Preparing"] = "preparing";
    OpenEdXInstanceDeploymentStatusStatusEnum["ChangesPending"] = "changes_pending";
})(OpenEdXInstanceDeploymentStatusStatusEnum = exports.OpenEdXInstanceDeploymentStatusStatusEnum || (exports.OpenEdXInstanceDeploymentStatusStatusEnum = {})); /**
* @export
* @enum {string}
*/
var OpenEdXInstanceDeploymentStatusDeploymentTypeEnum;
(function (OpenEdXInstanceDeploymentStatusDeploymentTypeEnum) {
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["User"] = "user";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Batch"] = "batch";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Admin"] = "admin";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Pr"] = "pr";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Periodic"] = "periodic";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Registration"] = "registration";
    OpenEdXInstanceDeploymentStatusDeploymentTypeEnum["Unknown"] = "unknown";
})(OpenEdXInstanceDeploymentStatusDeploymentTypeEnum = exports.OpenEdXInstanceDeploymentStatusDeploymentTypeEnum || (exports.OpenEdXInstanceDeploymentStatusDeploymentTypeEnum = {}));
function OpenEdXInstanceDeploymentStatusFromJSON(json) {
    return OpenEdXInstanceDeploymentStatusFromJSONTyped(json, false);
}
exports.OpenEdXInstanceDeploymentStatusFromJSON = OpenEdXInstanceDeploymentStatusFromJSON;
function OpenEdXInstanceDeploymentStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': json['status'],
        'undeployedChanges': json['undeployed_changes'],
        'deployedChanges': json['deployed_changes'],
        'deploymentType': json['deployment_type'],
    };
}
exports.OpenEdXInstanceDeploymentStatusFromJSONTyped = OpenEdXInstanceDeploymentStatusFromJSONTyped;
function OpenEdXInstanceDeploymentStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
        'undeployed_changes': value.undeployedChanges,
        'deployed_changes': value.deployedChanges,
        'deployment_type': value.deploymentType,
    };
}
exports.OpenEdXInstanceDeploymentStatusToJSON = OpenEdXInstanceDeploymentStatusToJSON;
