"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function InstanceReferenceMinimalFromJSON(json) {
    return InstanceReferenceMinimalFromJSONTyped(json, false);
}
exports.InstanceReferenceMinimalFromJSON = InstanceReferenceMinimalFromJSON;
function InstanceReferenceMinimalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'apiUrl': !runtime_1.exists(json, 'api_url') ? undefined : json['api_url'],
    };
}
exports.InstanceReferenceMinimalFromJSONTyped = InstanceReferenceMinimalFromJSONTyped;
function InstanceReferenceMinimalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {};
}
exports.InstanceReferenceMinimalToJSON = InstanceReferenceMinimalToJSON;
