"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * OpenCraft Instance Manager
 * API for OpenCraft Instance Manager
 *
 * The version of the OpenAPI document: api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
function OpenStackServerFromJSON(json) {
    return OpenStackServerFromJSONTyped(json, false);
}
exports.OpenStackServerFromJSON = OpenStackServerFromJSON;
function OpenStackServerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'apiUrl': !runtime_1.exists(json, 'api_url') ? undefined : json['api_url'],
        'created': !runtime_1.exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !runtime_1.exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'openstackId': !runtime_1.exists(json, 'openstack_id') ? undefined : json['openstack_id'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'publicIp': !runtime_1.exists(json, 'public_ip') ? undefined : json['public_ip'],
        'openstackRegion': !runtime_1.exists(json, 'openstack_region') ? undefined : json['openstack_region'],
    };
}
exports.OpenStackServerFromJSONTyped = OpenStackServerFromJSONTyped;
function OpenStackServerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'openstack_id': value.openstackId,
        'openstack_region': value.openstackRegion,
    };
}
exports.OpenStackServerToJSON = OpenStackServerToJSON;
