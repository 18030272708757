const messages = {
  dashboard: {
    defaultMessage: 'Dashboard',
    description: 'Dashboard dropdown item'
  },
  courseoutline: {
    defaultMessage: 'Course Outline',
    description: 'Course Outline Page'
  }
};

export default messages;
